
import { Options, Vue } from "vue-class-component";
import MButton from "@/components/MButton.vue";
import MIcon from "@/components/MIcon.vue";
import MLoadingIcon from "@/components/MLoadingIcon.vue";
import MTable from "@/components/MTable.vue";
import MNavBar from "@/components/MNavBar.vue";
import MHeaderPanel from "@/components/MHeaderPanel.vue";
import MScAddModal from "@/components/school/MScAddModal.vue";
import MscEditModal from "@/components/school/MscEditModal.vue";
import { School, schoolCollectionKey, SchoolConfig } from "@/entities/school";
import { getTutor } from "@/api/tutor";
import { Tutor } from "@/entities/tutor";
import firebase from "firebase/app";
import store from "@/store";
import { deleteChildSchoolfromParent, deleteSchool } from "@/api/school";
import { Target } from "@/entities/target";
import { Getter, State } from "@/store/helper";
import { saveErrorLog } from "@/api/error";
import {
  convertToSchoolTableData,
  schoolsTableDefinition
} from "@/utils/table";
import { getAllLoginTutors } from "@/api/user";
import { User } from "@/entities/user";

@Options({
  components: {
    MButton,
    MIcon,
    MLoadingIcon,
    MTable,
    MNavBar,
    MHeaderPanel,
    MScAddModal,
    MscEditModal
  },
  watch: {
    accessibleSchools: function() {
      if (this.accessibleSchools.length > 0) {
        this.updateSchoolDetails();
      }
    }
  }
})
export default class SchoolSettings extends Vue {
  isDisplayedAddSchoolModal = false;
  isDisplayedEditSchoolModal = false;
  searchKeyword = "";
  load = false;
  selectedId = "";
  selectedIds: string[] = [];
  schoolList: { [key: string]: string | number | boolean }[] = [];
  definitions = schoolsTableDefinition;
  tutors: User[] = [];

  @State("schools") schools!: School[];
  @State("schoolsForServieProvider") schoolsForServieProvider?: School[];
  @State("schoolConfigs") schoolConfigs!: SchoolConfig[];
  @State("schoolConfig") schoolConfig!: SchoolConfig;
  @State("targets") targets!: Target[];
  @Getter("isSuperAdmin") isSuperAdmin!: boolean;
  @Getter("isServiceProvider") isServiceProvider!: boolean;

  get accessibleSchools(): School[] {
    if (this.isSuperAdmin) {
      return this.schools;
    } else if (this.isServiceProvider) {
      return this.schoolsForServieProvider as School[];
    }
    return [];
  }

  get selectedSchool(): School | null {
    const matchSchools = this.accessibleSchools.filter(
      item => item.ref.id === this.selectedId
    );
    if (matchSchools.length === 0) {
      return null;
    }
    return matchSchools[0];
  }

  get selectedSchoolConfig(): SchoolConfig | null {
    const matchSchoolConfigs = this.schoolConfigs.filter(
      item => item.ref.id === this.selectedId
    );
    if (matchSchoolConfigs.length === 0) {
      return null;
    }
    return matchSchoolConfigs[0];
  }

  get filteredSchools(): { [key: string]: string | number | boolean }[] {
    if (this.searchKeyword.length === 0) {
      return this.schoolList;
    }

    return this.schoolList.filter(
      data =>
        data?.schoolId
          .toString()
          .toLowerCase()
          .includes(this.searchKeyword.toLowerCase()) ||
        data?.name
          .toString()
          .toLowerCase()
          .includes(this.searchKeyword.toLowerCase()) ||
        data?.owner
          .toString()
          .toLowerCase()
          .includes(this.searchKeyword.toLowerCase())
    );
  }

  get selectedTutors(): User[] {
    return this.tutors.filter(tutor => tutor.schoolId === this.selectedId);
  }

  showAddModal() {
    this.isDisplayedAddSchoolModal = true;
  }

  showEditModal(id: string) {
    this.selectedId = id;
    this.isDisplayedEditSchoolModal = true;
  }

  changeSelectedId(id: string) {
    if (this.selectedIds.includes(id)) {
      this.selectedIds = this.selectedIds.filter(item => item !== id);
    } else {
      this.selectedIds.push(id);
    }
  }

  async deleteSchool() {
    if (this.selectedIds.length !== 1) {
      return;
    }
    await store.commit("START_LOADING", "招待中生徒情報移行中...");
    const schoolConfigRef: firebase.firestore.DocumentReference<
      firebase.firestore.DocumentData
    > | null = this.schoolConfig?.ref ?? null;

    const idToDelete = this.selectedIds[0];
    try {
      await deleteChildSchoolfromParent(
        this.isServiceProvider,
        schoolConfigRef,
        idToDelete
      );
      await deleteSchool(
        firebase
          .firestore()
          .collection(schoolCollectionKey)
          .doc(idToDelete)
      );
      await store.commit("END_LOADING");
      alert("導入スクールの削除に成功しました");
      this.$router.go(0);
    } catch (e) {
      store.commit("END_LOADING");
      alert(e);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to delete school"
      );
      return;
    }
  }

  async created() {
    this.updateSchoolDetails();
    this.tutors = await getAllLoginTutors();
  }

  async updateSchoolDetails() {
    this.load = true;
    const owners = await this.getOwnerOfSchools(this.accessibleSchools);
    const sortedSchools = [...this.accessibleSchools];
    sortedSchools.sort((a, b) => b.data.id.localeCompare(a.data.id));
    this.load = false;

    this.schoolList = [];
    let index = 0;
    for (const school of sortedSchools) {
      this.schoolList.push(
        await convertToSchoolTableData(
          school,
          owners,
          this.schoolConfigs,
          this.targets
        )
      );
      index++;
      if (index % 40 === 0) {
        await new Promise(resolve => setTimeout(resolve, 100));
      }
    }
  }

  async getOwnerOfSchools(schools: School[]) {
    const owners = await Promise.all(
      schools
        .filter(school => school.data.owner !== null)
        .map(school => getTutor(school.data.owner!))
    );
    return owners.filter((owner): owner is Tutor => owner !== null);
  }
}
