
import MBaseModal from "@/components/MBaseModal.vue";
import MIcon from "@/components/MIcon.vue";
import MButton from "@/components/MButton.vue";
import MTextField from "@/components/form/MTextField.vue";
import MSelectBox from "@/components/form/MSelectBox.vue";
import store from "@/store";
import { Options, Vue } from "vue-class-component";
import { School, SchoolConfig, Role } from "@/entities/school";
import { Target } from "@/entities/target";
import dayjs from "dayjs";
import {
  updateSchool,
  updateSchoolConfig,
  updateSchoolRecess,
  reflectUpdatedRecessToChildren
} from "@/api/school";
import { updateStudentRecess } from "@/api/student";
import { convertToUnixtimeFromDate } from "@/utils/date";
import { saveErrorLog } from "@/api/error";
import { User } from "@/entities/user";

type PaymentType = {
  key: "uncontracted" | "stripe" | "external" | "none";
  name: string;
};

@Options({
  components: {
    MBaseModal,
    MIcon,
    MButton,
    MTextField,
    MSelectBox
  },
  emits: ["close"],
  props: {
    school: Object,
    schoolConfig: Object,
    schools: Array,
    targets: Array,
    tutors: Array,
    isSuperAdmin: Boolean,
    isServiceProvider: Boolean
  },
  watch: {
    addRole(newValue) {
      if (newValue === false) {
        this.role = [];
      }
    }
  }
})
export default class MscEditModal extends Vue {
  isSuperAdmin = false;
  isServiceProvider = false;
  school!: School;
  schoolConfig!: SchoolConfig;
  schoolId = "";
  schoolName = "";
  paymentType: "uncontracted" | "stripe" | "external" | "none" = "none";
  paymentTypes: PaymentType[] = [
    {
      key: "stripe",
      name: "クレジットカード (Stripe)"
    },
    {
      key: "external",
      name: "外部決済 (銀行振込等)"
    },
    {
      key: "uncontracted",
      name: "利用停止中 (未契約)"
    },
    {
      key: "none",
      name: "デモ・トライアル期間"
    }
  ];
  allowCreateRoom = false;
  allowUseOfCustomOfficialLine = false;
  activateDay = "";
  inRecess = false;
  selectedTargetId = "";
  schools: School[] = [];
  targets: Target[] = [];
  zoomUserId = "";
  lineOfficialAccountChannelAccessToken = "";
  lineOfficialAccountChannelSecret = "";
  role: Role[] = [];
  tutors: User[] = [];
  addRole = false;
  isEditedSchoolServiceProvider = false;
  useSchoolAiExclusively = false;
  hideLoginInvitaion = false;
  hideMingakuStudyRoom = false;

  get selectedTarget(): Target | null {
    const matchTargets = this.targets.filter(
      target => target.ref.id === this.selectedTargetId
    );
    if (matchTargets.length === 0) {
      return null;
    }
    return matchTargets[0];
  }

  get studentListLink(): string {
    return `/student?school=${this.school.ref.id}`;
  }

  get validId(): boolean {
    if (this.schoolId === this.school.data.id) {
      return true;
    }

    return (
      /^\d{6}$/.test(this.schoolId) &&
      !this.schools.map(school => school.data.id).includes(this.schoolId)
    );
  }

  get validName(): boolean {
    return this.schoolName.length > 0;
  }

  get validActivateDay(): boolean {
    if (this.schoolConfig.data.activateTime) {
      return true;
    }
    if (this.activateDay === "") {
      return true;
    }

    return /^\d{4}-\d{2}-\d{2}$/.test(this.activateDay);
  }

  get validData() {
    return this.validId && this.validName && this.validActivateDay;
  }

  async changeRecess() {
    store.commit("START_LOADING", "更新中...");
    try {
      const recessTime = this.schoolConfig.data.recessTime
        ? 0 // 休会解除
        : Math.floor(Date.now() / 1000); // 休会
      const classroomsSnapshot = await this.school.ref
        .collection("classrooms")
        .get();
      await Promise.all(
        classroomsSnapshot.docs.map(async doc => {
          const studentsSnapshot = await doc.ref.collection("students").get();
          studentsSnapshot.docs.map(async doc => {
            if (
              doc.data().recessTime !== 0 &&
              typeof doc.data().recessTime !== "undefined"
            )
              return; // すでに生徒が休会状態だったら、recessTime を更新しない
            await updateStudentRecess(doc.ref, recessTime); // 生徒の休会 or 休会解除
          });
        })
      );
      await Promise.all([
        updateSchoolRecess(this.schoolConfig.ref.id, recessTime),
        reflectUpdatedRecessToChildren(
          this.isEditedSchoolServiceProvider,
          this.schoolConfig.ref,
          recessTime
        )
      ]);
      store.commit("END_LOADING");
    } catch (e) {
      store.commit("END_LOADING");
      alert(e);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to change recess status"
      );
      return;
    }
    this.$router.go(0);
  }

  async update() {
    if (!this.validData || !this.selectedTarget) {
      return;
    }

    if (this.addRole && this.role.length === 0) {
      alert("役割を選択してください");
      return;
    }

    if (
      this.allowUseOfCustomOfficialLine &&
      (!this.lineOfficialAccountChannelAccessToken.trim() ||
        !this.lineOfficialAccountChannelSecret.trim())
    ) {
      alert(
        "独自公式LINEを利用する場合はチャネルアクセストークンとチャネルシークレットを入力してください"
      );
      return;
    }

    const recessTime = this.schoolConfig.data.recessTime ?? 0;
    const activateTime =
      this.activateDay !== "" ? convertToUnixtimeFromDate(this.activateDay) : 0;
    store.commit("START_LOADING", "更新中...");

    try {
      await Promise.all([
        updateSchool(this.school.ref, this.schoolId, this.schoolName),
        updateSchoolConfig(
          this.schoolConfig.ref,
          this.paymentType,
          recessTime,
          activateTime,
          this.allowCreateRoom,
          this.selectedTarget.ref,
          this.zoomUserId,
          this.role,
          this.useSchoolAiExclusively,
          this.hideLoginInvitaion,
          this.hideMingakuStudyRoom,
          this.allowUseOfCustomOfficialLine,
          this.lineOfficialAccountChannelAccessToken,
          this.lineOfficialAccountChannelSecret
        )
      ]);
      store.commit("END_LOADING");
    } catch (e) {
      store.commit("END_LOADING");
      alert(e);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to update school"
      );
      return;
    }

    this.$router.go(0);
  }

  close() {
    this.$emit("close");
  }

  created() {
    this.schoolId = this.school.data.id;
    this.schoolName = this.school.data.name;
    this.paymentType = this.schoolConfig.data.paymentType;
    if (this.schoolConfig.data.recessTime) {
      this.inRecess = true;
    }
    if (this.schoolConfig.data.activateTime) {
      this.activateDay = dayjs(this.schoolConfig.data.activateTime * 1000)
        .locale("ja")
        .format("YYYY-MM-DD");
    }
    this.selectedTargetId = this.schoolConfig.data.target.id;
    this.allowCreateRoom = this.schoolConfig.data.allowCreateRoom ?? false;
    this.zoomUserId = this.schoolConfig.data.zoomUserId ?? "";
    this.allowUseOfCustomOfficialLine =
      this.schoolConfig.data.allowUseOfCustomOfficialLine ?? false;
    this.lineOfficialAccountChannelAccessToken =
      this.schoolConfig.data.lineOfficialAccountChannelAccessToken ?? "";
    this.lineOfficialAccountChannelSecret =
      this.schoolConfig.data.lineOfficialAccountChannelSecret ?? "";

    this.role = this.schoolConfig.data.role
      ? [...this.schoolConfig.data.role]
      : [];
    this.addRole = this.role.length ? true : false;

    this.isEditedSchoolServiceProvider = this.schoolConfig.data.role
      ? this.schoolConfig.data.role.includes("serviceProvider")
      : false;

    this.useSchoolAiExclusively =
      this.schoolConfig.data.useSchoolAiExclusively ?? false;
    this.hideLoginInvitaion =
      this.schoolConfig.data.hideLoginInvitaion ?? false;
    this.hideMingakuStudyRoom =
      this.schoolConfig.data.hideMingakuStudyRoom ?? false;
  }
}
